@import url("./assets/Montserrat/stylesheet.css");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-family: "Montserrat";
  font-size: clamp(10px, 0.83vw, 18px);
}

main {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
  background: linear-gradient(0deg, rgba(220,244,255,1) 0%, rgba(220,188,255,1) 100%);
}

.taplink {
  position: relative;
  width: 40rem;
  padding: 2rem;
  box-shadow: 2px 2px 10px rgba(2, 34, 34, 0.12);
  border-radius: 0.5rem;
  background-color: #ffffff;
}

.taplink-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  width: 100%;
  padding: 1rem 0;
  color: #000000;
  font-size: 1.5rem;
  text-decoration: none;
}

.taplink-photo {
  position: absolute;
  top: -3rem;
  left: 2rem;
  width: 10rem;
  height: 10rem;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.taplink-name {
  padding-left: 12rem;
  padding-bottom: 5rem;
}

h2 {
  font-size: 1.7rem;
}

.taplink-text {
  margin-bottom: 1rem;
}

.taplink-icon {
  width: 2rem;
}

.taplink-icon img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .taplink {
    width: 90vw;
  }
}

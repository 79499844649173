@font-face {
  font-family: "Montserrat";
  src: url("Montserrat.ttf") format("ttf supports variations"),
    url("Montserrat.ttf") format("truetype-variations");
  font-weight: 300 900;
  font-stretch: 25% 150%;
  font-style: normal;
}

@font-face {
  font-family: "Montserrat";
  src: url("Montserrat-Italic.ttf") format("ttf supports variations"),
    url("Montserrat-Italic.ttf") format("truetype-variations");
  font-weight: 300 900;
  font-stretch: 25% 150%;
  font-style: italic;
}
